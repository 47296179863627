import React, { useRef, useEffect, useState } from 'react';
import { Send } from 'lucide-react';
import './MessageInput.css';

const MessageInput = ({ 
  newMessage, 
  setNewMessage, 
  setMessageListHeight, 
  handleSendMessage, 
  inputDisabled, 
  isLoading, 
  isStreaming, 
  isOpen 
}) => {
  const textareaRef = useRef(null);
  const [inputHeight, setInputHeight] = useState(0);
  const [userInteracted, setUserInteracted] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [placeholderState, setPlaceholderState] = useState('initial');


  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !inputDisabled && !isMobile) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleResize = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      const maxHeight = 100;
      const newHeight = Math.min(textarea.scrollHeight, maxHeight);
      textarea.style.height = `${newHeight}px`;
      textarea.style.overflowY = newHeight === maxHeight ? 'scroll' : 'hidden';
      setMessageListHeight(Math.max(newHeight + 20, 72));
      setInputHeight(newHeight);
    }
  };

  useEffect(() => {
    handleResize();
  }, [newMessage]);

  useEffect(() => {
    if (isOpen && newMessage.trim() === '') {
      setTimeout(() => setPlaceholderState('show'), 500);
    } else {
      setPlaceholderState('initial');
    }
  }, [isOpen]);

  const handleFocus = () => {
    setUserInteracted(true);
    setPlaceholderState('hide');
  };

  const handleBlur = () => {
    if (newMessage.trim() === '') {
      setPlaceholderState('show');
    }
  };

  const handleSendButtonClick = () => {
    handleSendMessage();
    setNewMessage('');
    setPlaceholderState('show');
  };

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);

    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  useEffect(() => {
    setButtonVisible(newMessage.trim() !== '' && !inputDisabled);
  }, [newMessage, inputDisabled]);

  return (
    <div className="message-input-container" style={{ height: `${inputHeight}px` }}>
      <div className={`message-input ${userInteracted ? 'active' : ''} ${isOpen ? 'expand-center' : ''}`}>
        <div className={`placeholder ${placeholderState}`}>
          Message here...
        </div>
        <textarea
          ref={textareaRef}
          value={newMessage}
          onChange={(e) => {
            setNewMessage(e.target.value);
            handleResize();
          }}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          rows="1"
          disabled={inputDisabled}
          placeholder=""
        />
        <div className="button-container">
          <button
            type="button"
            onClick={handleSendButtonClick}
            className={`send-button ${buttonVisible ? 'visible' : 'hidden'}`}
            disabled={inputDisabled || !buttonVisible}
          >
            <Send 
              size={24} 
              className="send-icon"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageInput;