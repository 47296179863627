import React from 'react';
import { format, isToday, isYesterday } from 'date-fns';
import './DateDivider.css';

const DateDivider = ({ timestamp }) => {
  const date = new Date(timestamp * 1000);

  const formatDate = (date) => {
    if (isToday(date)) return 'Today';
    if (isYesterday(date)) return 'Yesterday';
    return format(date, 'MMMM d, yyyy');
  };

  return (
    <div className="date-divider">
      <span className="date-divider-text">{formatDate(date)}</span>
    </div>
  );
};

export default DateDivider;