import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import { getMessages, sendMessageToChat } from '../api/apiService';
import { AuthContext } from './AuthContext';

export const ConversationContext = createContext();

export const ConversationProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [isLoadingOlderMessages, setIsLoadingOlderMessages] = useState(false);
  const [organizationId, setOrganizationId] = useState(null);
  const [agentId, setAgentId] = useState(null);
  const fetchMessagesRef = useRef(false);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === 'INITIALIZE') {
        setOrganizationId(event.data.organizationId);
        setAgentId(event.data.agentId);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  useEffect(() => {
    if (user && user.accessToken && organizationId && agentId && !fetchMessagesRef.current) {
      fetchMessagesRef.current = true;
      fetchMessages(user.accessToken, organizationId, agentId, user.id, null);
    }
  }, [user, organizationId, agentId]);

  const fetchMessages = async (token, organizationId, agentId, userId, nextPageToken = null) => {
    if (nextPageToken) {
      setIsLoadingOlderMessages(true);
    } else {
      setIsLoading(true);
    }

    try {
      const response = await getMessages(token, organizationId, agentId, userId, nextPageToken=null);
      const newMessages = response.data.messages.map(msg => ({ ...msg, status: 'sent' }));

      if (nextPageToken) {
        setMessages(prevMessages => [...newMessages.reverse(), ...prevMessages]);
      } else {
        setMessages(newMessages.reverse());
      }

      setNextPageToken(response.data.next_page_token);
    } catch (error) {
      console.error('Error fetching conversation:', error);
    } finally {
      setIsLoadingOlderMessages(false);
      setIsLoading(false);
    }
  };

  const loadMoreMessages = () => {
    if (user && user.accessToken && nextPageToken && organizationId && agentId && !isLoadingOlderMessages) {
      fetchMessages(organizationId, agentId, user.id, user.accessToken, nextPageToken);
    }
  };

  const updateMessage = (index, updates) => {
    setMessages(currentMessages => currentMessages.map((msg, i) => i === index ? { ...msg, ...updates } : msg));
  };

  const updateMessageStatus = (index, status) => {
    setMessages(currentMessages =>
      currentMessages.map((msg, i) => (i === index ? { ...msg, status } : msg))
    );
  };

  const sendMessage = async (messageContent) => {
    if (user && user.accessToken && organizationId && agentId) {
      setMessages(messages => [...messages, {
        content: messageContent,
        timestamp: Math.floor(Date.now() / 1000),
        status: 'sending',
        role: 'user',
      }]);
      const newMessageIndex = messages.length;
      updateMessageStatus(newMessageIndex, 'sent');

      try {
        setMessages(messages => [
          ...messages,
          {
            content: '',
            timestamp: Math.floor(Date.now() / 1000),
            status: 'typing',
            role: 'assistant',
          },
        ]);

        const lastMessages = messages.slice(-10).map(msg => ({ role: msg.role, content: msg.content }));

        const response = await sendMessageToChat(user.accessToken, organizationId, agentId, user.id, { user_input: messageContent, messages: lastMessages });
        setMessages(messages => messages.map((msg, i) => i === newMessageIndex + 1 ? { ...msg, content: response.data.content, status: 'sent', stream: true } : msg));
      } catch (error) {
        console.error('Error sending message:', error);
        setMessages(messages => messages.slice(0, -1));
        updateMessageStatus(newMessageIndex, 'failed');
      }
    }
  };

  const retrySendMessage = async (index) => {
    if (user && user.accessToken && organizationId && agentId) {
      const message = messages[index];
      if (message.status === 'failed') {
        updateMessageStatus(index, 'sending');
        try {
          setMessages(messages => [
            ...messages,
            {
              content: '',
              timestamp: Math.floor(Date.now() / 1000),
              status: 'typing',
              role: 'assistant',
            },
          ]);

          const response = await sendMessageToChat(user.accessToken, organizationId, agentId, user.id, { user_input: message.content, messages: [{"role": "assistant", content: "Hi, I am AI. How can I help you today?"}] });
          setMessages(messages => messages.map((msg, i) => i === index + 1 ? { ...msg, content: response.data.content, status: 'sent', stream: true } : msg));
        } catch (error) {
          console.error('Error retrying message:', error);
          setMessages(messages => messages.slice(0, -1));
          updateMessageStatus(index, 'failed');
        }
      }
    }
  };

  return (
    <ConversationContext.Provider
      value={{
        messages,
        isLoadingOlderMessages,
        isLoading,
        sendMessage,
        retrySendMessage,
        loadMoreMessages,
        updateMessage,
        organizationId,
        setOrganizationId,
        agentId,
        setAgentId,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};