import React, { Fragment, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import AnimatedContent from "../AnimatedContent/AnimatedContent";
import LoadingMessage from "./LoadingMessage";
import { formatDistanceToNow } from "date-fns";
import "./Message.css";

const Message = ({
  content,
  role,
  status,
  index,
  timestamp,
  retrySendMessage,
  stream,
  onStreamingComplete,
  messagesEndRef,
  isScrolledToBottom,
}) => {
  useEffect(() => {
    if (isScrolledToBottom && messagesEndRef?.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isScrolledToBottom, messagesEndRef]);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";
    const date = new Date(timestamp * 1000);
    return formatDistanceToNow(date, { addSuffix: true });
  };
  
  if (status === "typing") {
    return (
      <div className={`message ${role} loading fade-in`}>
        <div className="message-text">
          <span className={`text-background ${role}`}>
            <LoadingMessage />
          </span>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div key={`${role}-${index}`} className={`message ${role} ${status} fade-in`}>
        <div className="message-text">
          {stream ? (
            <span className={`text-background ${role}`}>
              <AnimatedContent
                content={content}
                animationType="stream"
                onAnimationComplete={onStreamingComplete}
              />
            </span>
          ) : (
            <span className={`text-background ${role}`}>
              <ReactMarkdown className="message-content">
                {content}
              </ReactMarkdown>
              <time className="message-timestamp">
                {formatTimestamp(timestamp)}
              </time>
            </span>
          )}
          {status === "failed" && (
            <button
              className="retry-button"
              onClick={() => retrySendMessage(index)}
            >
              Retry
            </button>
          )}
        </div>
      </div>
      {messagesEndRef && <div ref={messagesEndRef} />}
    </Fragment>
  );
};

export default Message;