import React from 'react';
import { startOfDay } from 'date-fns';
import LoadingSkeleton from '../LoadingSkeleton/LoadingSkeleton';
import Message from '../Message/Message';
import DateDivider from '../DateDivider/DateDivider';
import './MessageList.css';

const MessageList = ({
  messagesListRef,
  isLoadingOlderMessages,
  isLoading,
  messages,
  retrySendMessage,
  handleStreamingComplete,
  isScrolledToBottom,
  inputHeight,
}) => {
  const shouldShowDateDivider = (currentMsg, prevMsg) => {
    if (!prevMsg) return true;
    const currentDate = startOfDay(new Date(currentMsg.timestamp * 1000));
    const prevDate = startOfDay(new Date(prevMsg.timestamp * 1000));
    return currentDate.getTime() !== prevDate.getTime();
  };

  const renderMessages = () => {
    return messages.map((msg, index) => {
      const elements = [];
      if (shouldShowDateDivider(msg, messages[index - 1])) {
        elements.push(
          <DateDivider key={`divider-${msg.timestamp}`} timestamp={msg.timestamp} />
        );
      }
      elements.push(
        <Message
          key={msg.id || index}
          {...msg}
          index={index}
          retrySendMessage={retrySendMessage}
          onStreamingComplete={() => handleStreamingComplete(index)}
          isScrolledToBottom={isScrolledToBottom}
        />
      );
      return elements;
    }).flat();
  };

  return (
    <ul
      className="messages-list"
      ref={messagesListRef}
      style={{
        paddingBottom: `${inputHeight}px`,
      }}
    >
      <div className="messages-list-content">
        {isLoadingOlderMessages && (
          <div className="spinner-messages">
            <LoadingSkeleton />
          </div>
        )}
        {!isLoading && messages.length > 0 ? (
          <>
            {renderMessages()}
            {isLoading && (
              <Message
                role="assistant"
                content=""
                status="loading"
                timestamp={Date.now() / 1000}
                index={-1}
              />
            )}
          </>
        ) : (
          !isLoadingOlderMessages && <LoadingSkeleton />
        )}
      </div>
    </ul>
  );
};

export default MessageList;