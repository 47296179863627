import { useEffect, useRef, useCallback, useState } from 'react';

const LOAD_COOLDOWN = 1000;
const LOAD_THRESHOLD_PERCENT = 40;

const useScrollAndLoad = (
  messages,
  isLoadingOlderMessages,
  loadMoreMessages,
  isOpen,
  inputHeight // Add inputHeight as a parameter
) => {
  // Add effect to handle safe area bottom changes
  useEffect(() => {
    const handleSABChange = () => {
      const list = messagesListRef.current;
      if (!list) return;
      
      if (isScrolledToBottom) {
        requestAnimationFrame(() => {
          scrollToBottom(false);
        });
      }
    };

    // Watch for changes in --sab CSS variable
    const observer = new MutationObserver(handleSABChange);
    const root = document.documentElement;
    observer.observe(root, {
      attributes: true,
      attributeFilter: ['style']
    });

    return () => observer.disconnect();
  }, [isScrolledToBottom, scrollToBottom]);
  const messagesListRef = useRef(null);
  const lastLoadTime = useRef(0);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const previousScrollHeight = useRef(0);
  const previousMessagesLength = useRef(messages.length);
  const previousInputHeight = useRef(inputHeight);

  const scrollToBottom = useCallback((smooth = true) => {
    if (messagesListRef.current) {
      const list = messagesListRef.current;
      const scrollTarget = list.scrollHeight - list.clientHeight;
      list.scrollTo({
        top: scrollTarget,
        behavior: smooth ? 'smooth' : 'auto',
      });
      setIsScrolledToBottom(true);
    }
  }, []);

  const handleLoadMore = useCallback(() => {
    const now = Date.now();
    if (now - lastLoadTime.current > LOAD_COOLDOWN && !isLoadingOlderMessages) {
      if (messagesListRef.current) {
        previousScrollHeight.current = messagesListRef.current.scrollHeight;
      }
      loadMoreMessages();
      lastLoadTime.current = now;
    }
  }, [loadMoreMessages, isLoadingOlderMessages]);

  const handleScroll = useCallback(() => {
    const list = messagesListRef.current;
    if (!list) return;

    const { scrollTop, scrollHeight, clientHeight } = list;
    const scrollPosition = scrollTop;
    const totalScrollableHeight = scrollHeight - clientHeight;
    const scrollPercentage = (scrollPosition / totalScrollableHeight) * 100;

    const isAtBottom = Math.abs((scrollTop + clientHeight) - scrollHeight) <= 2;
    setIsScrolledToBottom(isAtBottom);

    if (scrollPercentage <= LOAD_THRESHOLD_PERCENT) {
      handleLoadMore();
    }
  }, [handleLoadMore]);

  // Add effect to handle input height changes
  useEffect(() => {
    const list = messagesListRef.current;
    if (!list || previousInputHeight.current === inputHeight) return;

    const heightDifference = inputHeight - previousInputHeight.current;
    const isNearBottom = Math.abs(list.scrollHeight - list.scrollTop - list.clientHeight) <= 2;
    
    if (isNearBottom || isScrolledToBottom) {
      // If we're at or very near the bottom, scroll to new bottom
      scrollToBottom(false);
    } else {
      // Calculate the actual scroll adjustment needed
      const currentScroll = list.scrollTop;
      const targetScroll = currentScroll + heightDifference;
      
      // Adjust scroll position while preserving relative view position
      requestAnimationFrame(() => {
        list.scrollTop = targetScroll;
      });
    }

    previousInputHeight.current = inputHeight;
  }, [inputHeight, isScrolledToBottom, scrollToBottom]);

  useEffect(() => {
    const list = messagesListRef.current;
    if (list) {
      list.addEventListener('scroll', handleScroll);
      return () => list.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    if (isOpen && messages.length > 0) {
      scrollToBottom(false);
    }
  }, [isOpen, scrollToBottom]);

  useEffect(() => {
    const list = messagesListRef.current;
    if (!list) return;

    if (isLoadingOlderMessages) {
      return;
    }

    const newMessagesLength = messages.length;

    if (previousMessagesLength.current < newMessagesLength) {
      if (previousScrollHeight.current) {
        const newScrollHeight = list.scrollHeight;
        const scrollDifference = newScrollHeight - previousScrollHeight.current;
        list.scrollTop += scrollDifference;
        previousScrollHeight.current = 0;
      } else if (isScrolledToBottom) {
        scrollToBottom();
      }
    }

    previousMessagesLength.current = newMessagesLength;
  }, [messages, isLoadingOlderMessages, isScrolledToBottom, scrollToBottom]);

  return { messagesListRef, isScrolledToBottom, scrollToBottom };
};

export default useScrollAndLoad;