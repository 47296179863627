import React from "react";
import { X } from "lucide-react";
import "./Header.css";

const Header = ({ icon, isOpen, toggleChat }) => {
  return (
    <div className="chat-header">
      <div className="header-left">
        <div className="company-logo-container">
          <img className="company-logo" src={icon} alt="Company Logo" />
        </div>
        <h1 className="chat-header-title">Support Chat</h1>
      </div>
      {isOpen && (
        <button onClick={toggleChat} className="chat-close-button">
          <X size={24} />
        </button>
      )}
    </div>
  );
};

export default Header;